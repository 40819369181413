import { Divider, Paper } from '@mui/material';
import {
  DatagridConfigurable,
  DateField,
  FunctionField,
  NumberField,
  SelectColumnsButton,
  TextField,
  TopToolbar,
  useRecordContext,
  useRefresh
} from 'react-admin';

import { environment } from '../../../Config';
import CurrencyField from '../../../components/CurrencyField';
import RequireUserCountry from '../../../components/RequireUserCountry';
import { CreditResourceDetails } from '../../../types';
import BreakdownItemWrapper from '../components/BreakdownItemField';
import RemoveFeeButton from '../components/RemoveFeeButton';
import { transformPaybacks } from '../utils/paybacks';
import OverdueDiscountButton from './components/OverdueDiscountButton';
import PaymentFreeMonthButton from './components/PaymentFreeMonthButton';
import ResetPaybackPlanButton from './components/ResetPaybackPlanButton';

const PaybackPlan = () => {
  const refresh = useRefresh();
  const record = useRecordContext<CreditResourceDetails>();

  if (!record?.plan?.paybackPlan) {
    return null;
  }

  const transformedPaybacks = transformPaybacks(record.plan.paybackPlan);

  return (
    <>
      <TopToolbar sx={{ alignItems: 'center', paddingRight: '8px !important', gap: '16px' }}>
        {environment.env !== 'production' && <SelectColumnsButton />}
        <RequireUserCountry record={record.user} countries={['SE']}>
          <Divider sx={{ height: 22, m: 0.5 }} orientation="vertical" />
          <ResetPaybackPlanButton onSuccess={refresh} />
          <Divider sx={{ height: 22, m: 0.5 }} orientation="vertical" />
          <PaymentFreeMonthButton onSuccess={refresh} />
          <Divider sx={{ height: 22, m: 0.5 }} orientation="vertical" />
          <OverdueDiscountButton onSuccess={refresh} />
        </RequireUserCountry>
      </TopToolbar>

      <Paper>
        <DatagridConfigurable
          data={transformedPaybacks}
          total={transformedPaybacks.length}
          isLoading={false}
          sort={{ field: 'period', order: 'ASC' }}
          bulkActionButtons={false}
          aria-label="payback plan table"
          sx={{
            // If the row has a button, make nested table rows taller
            // This accounts for the remove fee button in the nested table
            '& .MuiTableRow-root:has(.MuiButton-root) .MuiTableBody-root .MuiTableRow-root': {
              height: '40px'
            }
          }}
        >
          <NumberField source="period" />
          <FunctionField
            source="dueDate"
            render={(record: typeof transformedPaybacks[number]) => (
              <DateField
                source="dueDate"
                record={{ dueDate: record.dueDate.replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3') }}
              />
            )}
            textAlign="right"
          />
          <NumberField source="paybackId" />
          <TextField source="type" />
          <CurrencyField
            source="amount"
            record={{ country: record.agreement.country, ...record }}
            textAlign="right"
          />
          <TextField source="status" />

          <BreakdownItemWrapper label="Due" textAlign="right">
            <CurrencyField source="amount" record={{ country: record.agreement.country }} textAlign="right" />
          </BreakdownItemWrapper>
          <BreakdownItemWrapper label="Invoice Item">
            <TextField source="type" />
            <RemoveFeeButton agreementId={String(record.id)} />
          </BreakdownItemWrapper>
        </DatagridConfigurable>
      </Paper>
    </>
  );
};

export default PaybackPlan;
