import * as credit from './credit';
import * as bankId from './external/bankId';
import * as manualPayments from './manualPayments';
import * as manualReview from './manualReview';
import * as paymentProtectionInsurance from './paymentProtectionInsurance';
import * as productTiers from './productTiers';
import * as user from './user';

const API = {
  external: {
    bankId
  },
  credit,
  manualPayments,
  manualReview,
  paymentProtectionInsurance,
  productTiers,
  user
};

export default API;
