import { FunctionField, FunctionFieldProps } from 'react-admin';

import { ProductName } from '../../../../constants/ProductName';
import { ProductNameMap } from '../../../../types';
import { Transaction, TransactionType } from '../../../../types/paymentPlan';

const TransactionTypeField = (props: Omit<FunctionFieldProps, 'render'>) => (
  <FunctionField
    label="Type"
    render={(transaction: Transaction) => {
      const isProductVisibleAfterType = transaction.type === TransactionType.PAY_EXTRA && transaction.product;

      return isProductVisibleAfterType
        ? `${transaction.type} ${ProductNameMap[transaction.product as ProductName]}`
        : `${transaction.type}`;
    }}
    {...props}
  />
);

export default TransactionTypeField;
