import { RaRecord } from 'ra-core';

import { ProductName } from '../constants/ProductName';
import { Payback, Transaction } from './paymentPlan';
import { PaymentProvider } from './paymentProvider';

export type CreditResource = RaRecord & {
  id: string;
  contractId: string;
  userName: string;
  userMobile: string;
  userEmail: string;
  productTierId: string;
  amountGranted: number;
  amountUsed: number;
  annualInterest: number;
  effectiveInterest: number;
  trUserBank: string;
  trAccountId: string;
  trDirectDebtMandate: boolean;
  status: 'Granted' | string;
  createdAt: Date;
  directPayout: boolean;
  underManualReview: boolean;
};

export type IneligibilityReason =
  | 'AGREEMENT_NOT_IN_PROPER_STATUS'
  | 'ONE_OR_MORE_OVERDUES'
  | 'LESS_THAN_TWO_OVERDUES'
  | 'ACTIVE_OFFER'
  | 'OFFER_GRANTED_LESS_THAN_A_YEAR_AGO';

type CustomerSupportActions = {
  resetPaybackPlanOffer: {
    isEligible: boolean;
    lastOfferDate?: string;
    lastCompletionDate?: string;
  };
  paymentFreeMonthOffer: {
    isEligible: boolean;
    lastOfferDate?: string;
    lastCompletionDate?: string;
    ineligibilityReason?: IneligibilityReason;
  };
  overdueCampaignOffer: {
    isEligible: boolean;
    lastOfferDate?: string;
    lastCompletionDate?: string;
    ineligibilityReason?: IneligibilityReason;
  };
};

export type CreditResourceDetails = RaRecord & {
  agreement: CreditResource & {
    attachment: AgreementAttachment[];
  };
  blocked: {
    blocked: boolean;
    comment?: string;
    createdAt?: string;
    deactivated?: string;
  };
  paymentProtectionInsurance: {
    status: 'NotInsured' | 'Active' | 'Terminated' | 'ToBeTerminated';
    contractId?: string;
    previousClaimPayoutDate?: string;
    nextPayoutAmount?: number;
    registrationDate?: string;
    logs?: { createdAt: string; event: 'Active' | 'ToBeTerminated' | 'Terminated' }[];
    attachments?: { name: string; url: string }[];
  };
  user: User;
  userComment: UserComment[];
  plan: {
    paybackPlan: Payback[];
    transactions: Transaction[];
  };
  customerSupportActions: CustomerSupportActions;
};

export type BankDetailsSE = {
  directDebitMandate: boolean;
  directPayout: boolean;
  accountNumber: string;
  bankName: string;
};

export type BankDetailsUK = {
  directDebitMandate: boolean;
  bankName: string;
  directDebitStatus: 'SENT' | 'VERIFIED' | 'FAILED' | 'NEW' | 'TERMINATED';
  manualPaymentReference: string;
  accountStatus: 'incomplete' | 'manualApproval' | 'complete';
  repaymentDay: number;
  sortCode: string;
  accountNumber: string;
};

export type User = RaRecord & {
  id: string;
  nationalNumber: string;
  mobile: string;
  email: string;
  firstName: string;
  givenName: string;
  lastName: string;
  country: Country;
  paymentProvider: PaymentProvider;
  address: {
    postCode?: string;
    address: string;
    town?: string;
    coAddress?: string;
    street?: string;
    buildingNumber?: string;
    buildingName?: string;
  };
  bankDetails: BankDetailsSE | BankDetailsUK;
  riskLevel: 'LOW' | 'MEDIUM' | 'HIGH';
};

export type UserComment = RaRecord & {
  id: string;
  comment: string;
  commentBy: string;
  createdAt: string;
  notificationEmail: string;
  notificationPhoneNumber: string;
  userId: string;
};

export const COUNTRIES = ['SE', 'UK'] as const;
export type Country = typeof COUNTRIES[number];

type AgreementAttachment = {
  name: string;
  url: string;
};

/**
 * Types to eventually add in react-admin
 */
export interface FieldProps<T extends RaRecord = RaRecord> {
  addLabel?: boolean;
  label?: string;
  record?: T;
  source?: string;
}

export const ProductNameMap: Record<ProductName, string> = {
  [ProductName.CREDIT]: 'Varda',
  [ProductName.DEBT_CONSOLIDATION]: 'Lösa'
};
