import { environment } from '../../Config';
import { httpClient } from '../../dataprovider';

type RemoveFeeProps = {
  agreementId: string;
  transactionId: string;
};
export const removeFee = async ({ agreementId, transactionId }: RemoveFeeProps) => {
  await httpClient(`${environment.apiRoot}/credit/${agreementId}/remove-fee`, {
    method: 'POST',
    body: JSON.stringify({ id: transactionId })
  });
};
