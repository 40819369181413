import { Delete as DeleteIcon } from '@mui/icons-material';
import { HttpError, useNotify, useRecordContext, useRefresh } from 'ra-core';
import { useState } from 'react';
import { Button, Confirm } from 'react-admin';
import { useMutation } from 'react-query';

import API from '../../../api';
import { PaybackBreakDownItem } from '../types';

type Props = {
  agreementId: string;
};

const RemoveFeeButton = ({ agreementId }: Props) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const details = useRecordContext<PaybackBreakDownItem>();

  const [isConfirmOpen, setIsConfirmOpen] = useState(false);

  const { mutate: removeFee, isLoading: isRemoveFeeLoading } = useMutation(API.credit.removeFee, {
    onSuccess: () => {
      notify('Fee removed!', { type: 'success' });
    },
    onError: error => {
      if (error instanceof HttpError && error?.body?.error?.message) {
        notify(error.body.error.message, { type: 'error' });
      } else {
        notify('An error occurred while removing the fee', { type: 'error' });
      }
    },
    onSettled: () => {
      setIsConfirmOpen(false);
      refresh();
    }
  });

  if (!('isRemovable' in details) || !details.isRemovable) {
    return null;
  }

  return (
    <>
      <Button variant="outlined" label="Remove" onClick={() => setIsConfirmOpen(true)}>
        <DeleteIcon />
      </Button>

      <Confirm
        isOpen={isConfirmOpen}
        loading={isRemoveFeeLoading}
        title="Remove Fee"
        content="Are you sure you want to remove this fee?"
        onConfirm={() => {
          removeFee({
            agreementId,
            transactionId: details.id
          });
        }}
        onClose={() => setIsConfirmOpen(false)}
      />
    </>
  );
};

export default RemoveFeeButton;
