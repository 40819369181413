import { ReactNode } from 'react';
import { ArrayField, ArrayFieldProps, Datagrid } from 'react-admin';

type Props = Omit<ArrayFieldProps, 'children'> & {
  children: ReactNode;
};

const BreakdownItemWrapper = ({ children, ...restProps }: Props) => {
  return (
    <ArrayField source="breakdownItems" sortable={false} {...restProps}>
      <Datagrid
        bulkActionButtons={false}
        header={() => null}
        hover={false}
        padding="none"
        sx={{ '& .MuiTableCell-root': { borderBottom: 'none', paddingBlock: '4px' } }}
      >
        {children}
      </Datagrid>
    </ArrayField>
  );
};

export default BreakdownItemWrapper;
