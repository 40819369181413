import {
  isCreditPaybackItem,
  isDebtConsolidationPaybackItem,
  Payback,
  PaybackItem,
  PaybackItemType
} from '../../../types/paymentPlan';
import { PaybackBreakDownItem } from '../types';

const formatProductType = (item: PaybackItem): string => {
  let productPrefix = '';
  if (isCreditPaybackItem(item)) {
    productPrefix = 'VARDA';
  } else if (isDebtConsolidationPaybackItem(item)) {
    productPrefix = 'LÖSA';
  }

  let productItemType = item.type as string;
  if (productPrefix) {
    switch (item.type) {
      case PaybackItemType.PAYBACK_INTEREST:
        productItemType = `${productPrefix}_INTEREST`;
        break;
      case PaybackItemType.PAYBACK_PRINCIPAL:
        productItemType = `${productPrefix}_PRINCIPAL`;
        break;
    }
  }

  return productItemType;
};

const resolveBreakdownItems = (items: PaybackItem[]) => {
  const breakdownItems: PaybackBreakDownItem[] = items
    .map(item => {
      return {
        id: item.id,
        amount: item.amount,
        type: formatProductType(item),
        amountPaid: 'amountPaid' in item ? item.amountPaid : undefined,
        isRemovable: 'isRemovable' in item ? item.isRemovable : undefined
      };
    })
    .sort((a, b) => (a.type > b.type ? -1 : 1));

  return breakdownItems;
};

export const transformPaybacks = <T extends Payback>(paybacks: T[]) => {
  return paybacks.map(payback => ({
    ...payback,
    breakdownItems: resolveBreakdownItems(payback.items)
  }));
};
