import { ProductName } from '../constants/ProductName';

export enum InvoiceStatus {
  PAID = 'Paid',
  OVERDUE = 'Overdue',
  CHARGE = 'Charge',
  NOT_PAID = 'Not paid',
  PAYMENT_FREE_MONTH = 'Payment Free Month'
}

export enum TransactionType {
  PAY_EXTRA = 'PayExtra',
  COMPLETE_LOAN = 'CompleteLoan',
  PAYBACK = 'Payback',
  PAYOUT = 'Payout',
  ADMIN_PAY_EXTRA = 'AdminPayExtra',
  SOLD_BAD_DEBT = 'SoldBadDebt',
  OVERPAYMENT = 'Overpayment',
  REMAINING_AMOUNT_WRITTEN_OFF = 'REMAINING_AMOUNT_WRITTEN_OFF'
}

export enum PaybackItemType {
  PAYBACK_PRINCIPAL = 'PAYBACK_PRINCIPAL',
  PAYBACK_INTEREST = 'PAYBACK_INTEREST',
  EXTENDED_DUE_DATE_INTEREST = 'EXTENDED_DUE_DATE_INTEREST',
  OVERDUE_FEE = 'OVERDUE_FEE',
  DEBT_COLLECTION_FEE = 'DEBT_COLLECTION_FEE',
  DOUBLE_OVERDUE_FEE = 'DOUBLE_OVERDUE_FEE',
  DEBT_COLLECTION_INTEREST = 'DEBT_COLLECTION_INTEREST',
  REMIT = 'REMIT',
  ACCRUED_INTEREST = 'ACCRUED_INTEREST',
  REMAINING_AMOUNT_WRITTEN_OFF = 'REMAINING_AMOUNT_WRITTEN_OFF'
}

type PaybackMetaData = {
  // principal amount the accrued interest is calculated on
  principal: number;
  // Dates between the accrued interest is calculated on
  fromDate: Date;
  toDate: Date;
};

interface PaybackItemBase {
  type: PaybackItemType;
  amount: number;
  amountPaid?: number;
  date: string;
}

export interface CreditPaybackItem extends PaybackItemBase {
  id: string;
  uuid: string;
  paybackId: string;
  metaData: PaybackMetaData;
  agreementId: string;
  creditAgreementId: null;
}

export interface DebtConsolidationPaybackItem extends PaybackItemBase {
  id: string;
  uuid: string;
  paybackId: string;
  metaData: PaybackMetaData;
  agreementId: null;
  creditAgreementId: string;
}

type TransactionItem = {
  id: string;
  type: TransactionType;
  amount: number;
  isRemovable: boolean;
};

export type PaybackItem = CreditPaybackItem | DebtConsolidationPaybackItem | TransactionItem;

export type Payback = {
  paybackId: string;
  dueDate: string; // format YYYYMMDD
  sortDate: string;
  amount: number;
  account: string;
  period: number;
  status: InvoiceStatus;
  items: PaybackItem[];
  type: TransactionType;
  product?: ProductName;
};

export type Transaction = Payback & {
  transactionDate: string;
};

export const isCreditPaybackItem = (item: PaybackItem): item is CreditPaybackItem =>
  'agreementId' in item && Boolean(item.agreementId);

export const isDebtConsolidationPaybackItem = (item: PaybackItem): item is DebtConsolidationPaybackItem =>
  'creditAgreementId' in item && Boolean(item.creditAgreementId);

export const isTransactionItem = (item: PaybackItem): item is TransactionItem =>
  !isCreditPaybackItem(item) && !isDebtConsolidationPaybackItem(item);
